import { Button, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import {useDropzone} from 'react-dropzone'
import './App.css';
import FileElement from './FileElement';
import axios from 'axios';

export default function App() {
  const [files, setFiles] = useState();
  const [error, setError] = useState();
  const [links, setLinks] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const removeFile = (file) => {
    let temp = [...files];
    temp.splice(files.indexOf(file), 1);
    setFiles(temp);
  }

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData();
    files.forEach(x => formData.append("File" + files.indexOf(x), x));

    axios.post("https://api.smileycat.joe.dj/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {console.log(res.data); setLinks(); setFiles(); setError(); setLinks(res.data)}).catch(error => {setLinks(); setError(error.message);});
  };

  const showFiles = (files === null || files === undefined || files.length === 0);
  const isError = (error === null || error === undefined);
  const isLinks = (links !== null && links !== undefined);

  return (
      <div {...getRootProps({
        onClick: event => event.stopPropagation()
      })} id="dropzone">
        <div id="box" className={isDragActive ? "dashed" : ""}>
            <input {...getInputProps()} />
            <p>UPLOAD.SMILEYCAT.ML</p>
            <Stack spacing={2}>
            {
              showFiles ? <></> : files.map(x => <FileElement key={files.indexOf(x)} removeFile={removeFile} file={x}></FileElement>)
            }
            </Stack>
            <form onSubmit={submitForm}>
                <Button
                  variant="contained"
                  component="label"
                  style={{margin: "10px"}}
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    multiple="multiple"
                    onChange={(e) => {
                      if (e.target.files == null) {
                        return;
                      } else {
                        setFiles(Array.from(e.target.files))}
                      }
                    }
                  />
                </Button>
                <br />
                <Button type="submit" variant="contained" style={{margin: "10px"}}>Submit</Button>
            </form>
            {isError ? <></> : <div>{error}</div>}
            {isLinks ? links.map(s => <div className="file-link" style={{margin: "10px"}}><a target="_blank" href={s}>{s}</a></div>) : <></>}
          </div>
      </div>
  );
}

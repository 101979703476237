import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function FileElement({file, removeFile}) {
    return(
        <Item className="file">
            {file.name}
            <IconButton onClick={() => {removeFile(file)}}> <CloseIcon/> </IconButton>
        </Item>
    );
}